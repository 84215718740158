/* eslint-disable no-unused-vars */
import SERVICE_CONSTANTS from '../../shared/_constants/services.constants';
import axios from 'axios';
import { serviceFactory } from '../../services/_helpers/serviceFactory';
import {
  COOKIESPOLICY_CMS_DATA,
  COOKIESPOLICY_TABLE_CMS_DATA,
} from './CookiesPolicyActionTypes';

export const setCookiesPolicyCMSData = (data) => {
  return {
    type: COOKIESPOLICY_CMS_DATA,
    payload: data,
  };
};

export const setCookiesPolicyTableCMSData = (data) => {
  return {
    type: COOKIESPOLICY_TABLE_CMS_DATA,
    payload: data,
  };
};

export const fetchCookiesPolicyCMSData = (token) => {
  return (dispatch) => {
    const appendUrl = SERVICE_CONSTANTS.cookiePolicyPage;
    const cookiesPolicyApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`;
    axios
      .get(cookiesPolicyApi, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(setCookiesPolicyCMSData(response.data));
      })
      .catch((err) => {
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};

export const fetchCookiesPolicyTableCMSData = (token) => {
  return (dispatch) => {
    const appendUrl = SERVICE_CONSTANTS.cookiePolicyTableContent;
    const cookiesPolicyTableApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`;
    axios
      .get(cookiesPolicyTableApi, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(setCookiesPolicyTableCMSData(response.data));
      })
      .catch((err) => {
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};
