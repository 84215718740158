/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Switch, Router, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Notification, IamNav, Grid } from '@weirgroup/weir-dls';
import { useMsal } from '@azure/msal-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { msalConstants } from '../../services/msal.constants';
import { history } from '../../services/_helpers';
import styles from './main.module.css';
import { applicationRouteConstants } from '../../shared/_constants';
import '../../resources/stylesheets/main.css';
import SiteInformation from '../../modules/SiteInformation';
import PrivateRoute from './protectedRoute';
import Loader from '../../components/Loader/Loader';
import { localStorageOperation } from '../../shared/Helpers/localStorageData';
import { localStorageKeys } from '../../shared/_constants/localStorageKeys';
import { notificationActions } from '../../shared/_actions';
import FooterComponent from '../../components/Footer';
import TermsConditions from '../../components/TermsConditions';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import CookiesPolicy from '../../components/CookiesPolicy';

const MyAccessPage = lazy(() => import('../../modules/MyAccess'));
const MyActivityPage = lazy(
  () => import('../../modules/MyActivity/containers/MyActivityPage.container')
);

const LoginPage = lazy(() => import('../../modules/Login'));
const HubPage = lazy(
  () => import('../../modules/Hub/containers/HubPage.container')
);
const SignUpPage = lazy(() => import('../../modules/SignUp'));
const AppSignUp = lazy(() => import('../../modules/AppSignUp'));
const ErrorPage = lazy(() => import('../../modules/ErrorPage/ErrorPage'));
const SelectionPage = lazy(() => import('../../modules/SelectionPage'));

const Home = lazy(() => import('../../modules/Home'));
const Applications = lazy(() => import('../../modules/Applications'));

const ApplicationRoutes = () => {
  const location = useLocation();
  const appID = window.location.search.indexOf('app_id');
  const [autoPageHeight, setAutoPageHeight] = useState(false);

  useEffect(() => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/' ||
      location.pathname === '/selection-page'
    ) {
      if (!autoPageHeight) setAutoPageHeight(true);
    } else if (autoPageHeight) {
      setAutoPageHeight(false);
    }
  }, [location, autoPageHeight]);

  return (
    <div
      className={`${styles.contentMain} mainHeight ${
        autoPageHeight ? styles.heightAuto : ''
      }`}
    >
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route
            path={`${applicationRouteConstants.LOGIN}`}
            component={LoginPage}
          />
          <Route
            path={`${applicationRouteConstants.SIGNUP}`}
            component={appID >= 0 ? AppSignUp : SignUpPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.HUB}`}
            component={HubPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.MY_ACCESS}`}
            component={MyAccessPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.MY_ACTIVITY}`}
            component={MyActivityPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.SiteInformation}`}
            component={SiteInformation}
          />

          <PrivateRoute
            path={`${applicationRouteConstants.HOME}`}
            component={Home}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.APPLICATIONS}`}
            component={Applications}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.TERMSCONDITIONS}`}
            component={TermsConditions}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.PRIVACYPOLICY}`}
            component={PrivacyPolicy}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.COOKIESPOLICY}`}
            component={CookiesPolicy}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.SELECTION_PAGE}`}
            component={SelectionPage}
          />
          <Route component={ErrorPage} />
        </Switch>
      </Suspense>
    </div>
  );
};

const Main = ({ notification, hideNotification, showNotification, t }) => {
  const { instance } = useMsal();
  const detectPopupBlocker = () => {
    const popup = window.open('', '', 'width=100,height=100');
    try {
      popup.close();
    } catch (e) {
      showNotification(
        'alert',
        'Pop-ups blocked',
        'Please update the browser settings to allow pop-ups'
      );
    }
  };

  useEffect(() => {
    window.onpopstate = () => {
      window.location.assign(window.location.pathname);
    };
    detectPopupBlocker();

    let timeoutId;
    if (notification.showNotification) {
      timeoutId = setTimeout(() => {
        if (notification.showNotification) {
          hideNotification();
          notification.onClose?.();
        }
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [notification, hideNotification]);

  const isAuthenticatedUser = () => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/login' ||
      window.location.pathname === '/register'
    ) {
      return false;
    }
    return true;
  };

  const { homePageData } = useSelector((state) => state.homePageReducer);
  const showIamPortal = () => {
    if (homePageData.map((item) => item?.displayName === 'IAM Portal')) {
      return true;
    } else {
      return false;
    }
  };

  const showApplicationNavigation = () => {
    if (
      window.location.pathname === applicationRouteConstants.LOGIN ||
      window.location.pathname === applicationRouteConstants.SIGNUP ||
      window.location.pathname === applicationRouteConstants.SELECTION_PAGE
    ) {
      return false;
    }
    const userInfo = instance.getAccountByUsername(
      localStorageOperation.getDataFromLocalStorage(localStorageKeys.userName)
    );

    // Simplified return statement
    return !(userInfo === null || userInfo === undefined);
  };

  showApplicationNavigation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accountInfo = {
    profileName: '',
    profileFullName: '',
    email: '',
    buttonLabelLogout: 'Logout',
    myAppsLabel: '',
    isAdminAccess: { showIamPortal },
    iamPortalLabel: '',
    adminPortalLabel: '',
    linksLabel: 'Links',
    visitGlobalWeir: 'Visit global.weir',
    yourAccountMainHeading: '',
    deleteAccountMainHeading: '',
    deleteAccountPannelText: '',
    deleteNext: ``,
    nextPannelDesc: '',
    cancelButton: '',
    proceedButton: '',
    cancelDeleteButton: '',
    confirmDeleteButton: '',
    confirmDeletePannelDesc: '',
  };

  const supportItems = {
    unAuthUserInfo: {
      FirstName: 'Abc',
      LastName: 'Xyz',
      UserEmail: 'abc@gmail.com',
      CompanyName: 'Ensci',
    },
    isAuth: isAuthenticatedUser(),
    serviceNowToken: 'Token',
    serviceNowURL: 'https://weirgrouptest.service-now.com/',
    assignmentGroup: '3Db562a262c368e5504c43bf45df01315b',
    callerID: 'ce6e048f1b5d56100d3654292d4bcb31',
    applicationURL: 'https://apis.apps.weir/iamservice/',
  };

  const InAppItems = [
    {
      id: '1',
      name: 'Home',
      link: '/home',
      navigationOrder: 1,
      InAppMenu: 'Home',
      Href: '/home',
      openNewWindow: false,
    },
    {
      id: '2',
      name: 'Applications',
      link: '/applications',
      navigationOrder: 2,
      InAppMenu: 'Applications',
      Href: '/applications',
      openNewWindow: false,
    },
    {
      id: '3',
      name: 'News',
      link: '/news',
      navigationOrder: 3,
      InAppMenu: 'News',
      Href: '/newsroom/global-news/',
      openNewWindow: true,
    },
  ];

  return (
    <Router history={history}>
      <div>
        <div className={styles.background}>
          <div
            className={
              isAuthenticatedUser() ? styles.iamNavAuth : styles.iamNavUnAuth
            }
          >
            <IamNav
              appId={msalConstants.portalApplicationConfig.clientID}
              history={history}
              inAppNav
              contactTitle={t('contactForm.contactTitle')}
              contactAppname={t('contactForm.contactAppname')}
              subjectRequired={t('contactForm.subjectRequired')}
              subjectLabel={t('contactForm.subjectLabel')}
              subjectPlaceholder={t('contactForm.subjectPlaceholder')}
              messageRequired={t('contactForm.messageRequired')}
              messageLabel={t('contactForm.messageLabel')}
              messagePlaceholder={t('contactForm.messagePlaceholder')}
              buttonLabelClose={t('contactForm.buttonLabelClose')}
              buttonLabelSubmit={t('contactForm.buttonLabelSubmit')}
              contactDescription={t('contactForm.contactDescription')}
              notificationTitleSuccess={t(
                'contactForm.notificationTitleSuccess'
              )}
              notificationTitleError={t('contactForm.notificationTitleError')}
              notificationTextContent={t('contactForm.notificationTextContent')}
              redirectUri={`${window.location.origin}/blank.html`}
              showAccount={true}
              showSupportMenu={true}
              showContactMenu={false}
              supportFormItems={supportItems}
              accountInfo={accountInfo}
              inAppNavItems={InAppItems}
              applicationName="Portal"
              portalweirApiBaseurlPrep={
                process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP
              }
              baseUrl={process.env.REACT_APP_CDE_BASEURL}
              baseUrlv2={process.env.REACT_APP_CDE_BASEURLV2}
              iamServiceVersion={process.env.REACT_APP_CDE_IAMSERVICE_VERSION}
              headerVersion1={process.env.REACT_APP_CDE_HEADER_VERSION1}
              headerVersion2={process.env.REACT_APP_CDE_HEADER_VERSION2}
            />
          </div>
          {notification.showNotification && (
            <div className={styles.notification}>
              <Notification
                variant={notification.notificationType}
                title={notification.notificationTitle}
                onClose={() => {
                  hideNotification();
                  notification.onClose?.();
                }}
                textContent={notification.notificationMessage}
                callToActionList={notification.callToActionList}
              />
            </div>
          )}
          <div className={styles.grid}>
            <Grid desktopcolumns={12} tabletcolumns={8} mobilecolumns={4}>
              <ApplicationRoutes />
            </Grid>
          </div>
          <FooterComponent />
        </div>
      </div>
    </Router>
  );
};

Main.propTypes = {
  t: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  notificationType: PropTypes.func.isRequired,
  notificationMessage: PropTypes.func.isRequired,
  callToActionList: PropTypes.func.isRequired,
  notificationTitle: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isModal: state.HubReducer.isModal,
    notification: state.notification,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => {
      dispatch(notificationActions.hideNotification());
    },
    showNotification: (type, title, message) => {
      dispatch(
        notificationActions.showNotification({
          notificationType: type,
          notificationTitle: title,
          notificationMessage: message,
        })
      );
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Main));
