/* eslint-disable no-unused-vars */
import { PRIVACYPOLICY_CMS_DATA } from './PrivacyPolicyActionTypes';
import SERVICE_CONSTANTS from '../../shared/_constants/services.constants';
import axios from 'axios';
import { serviceFactory } from '../../services/_helpers/serviceFactory';

export const setPrivacyPolicyCMSData = (data) => {
  return {
    type: PRIVACYPOLICY_CMS_DATA,
    payload: data,
  };
};

export const fetchPrivacyPolicyCMSData = (token) => {
  return (dispatch) => {
    const appendUrl = SERVICE_CONSTANTS.privacyPolicyPage;
    const privacyPolicyApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`;
    axios
      .get(privacyPolicyApi, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(setPrivacyPolicyCMSData(response.data));
      })
      .catch((err) => {
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};
