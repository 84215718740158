import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { applicationRouteConstants } from '../../shared/_constants';

const isLoggedIn = localStorage.getItem('msal.idtoken');

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: applicationRouteConstants.DEFAULT,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default PrivateRoute;
