import { PRIVACYPOLICY_CMS_DATA } from './PrivacyPolicyActionTypes';

const initialState = {
  privacyPolicyCMSData: [],
};

export const privacyPolicyReducer = (state = initialState, action) => {
  if (action.type === PRIVACYPOLICY_CMS_DATA) {
    return {
      ...state,
      privacyPolicyCMSData: action.payload,
    };
  } else {
    return state;
  }
};
