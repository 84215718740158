// /* eslint-disable no-console */
import { jwtDecode } from 'jwt-decode';
import { apiRedirectUri } from './msal.constants';

const isTokenExpired = (token) => {
  if (!token) return true;
  const { exp } = jwtDecode(token);
  if (!exp) return true;
  const currentTime = Math.floor(Date.now() / 1000);
  return exp < currentTime;
};
async function getSilentToken(account, instance, scopes, type) {
  const accessTokenRequest = {
    scopes,
    account,
    redirectUri: apiRedirectUri,
  };

  const scopeVal = scopes[0];
  if (scopeVal.indexOf('allresources.access.asuser') > -1) {
    // Check for existing token and its validity
    if (
      window.localStorage.getItem('cdeSharedApisScopeToken') !== null &&
      window.localStorage.getItem('cdeSharedApisScopeToken') !== undefined &&
      window.localStorage.getItem('cdeSharedApisScopeToken') !== 'null'
    ) {
      const token = window.localStorage.getItem('cdeSharedApisScopeToken');
      if (!isTokenExpired(token)) {
        return token;
      }
      try {
        // const newToken = await instance.acquireTokenPopup(accessTokenRequest);
        const token = await instance.acquireTokenSilent(accessTokenRequest);
        // return token?.accessToken;
        window.localStorage.setItem(
          'cdeSharedApisScopeToken',
          token?.accessToken
        );
        return token?.accessToken;
      } catch (e) {
        console.error(`110: ${e}`);
        console.log('16: silent token error: ', e);
        if (e.name === 'InteractionRequiredAuthError') {
          return instance
            .acquireTokenPopup(accessTokenRequest)
            .then((response) => {
              // get access token from response
              window.localStorage.setItem(
                'cdeSharedApisScopeToken',
                response?.accessToken
              );
              return type === 'roles'
                ? response?.account?.idTokenClaims?.roles
                : response?.accessToken;
            })
            .catch((error) => {
              console.error(`24: ${error}`);
              throw error;
            });
        } else {
          console.error('Silent token acquisition error: ', e);
          throw e;
        }
      }
    } else {
      // Check for existing token and its validity
      const accounts = instance.getAllAccounts();
      if (accounts?.length > 0) {
        // checkAndSetActiveAccount();
        // await loginSSO(account);
        try {
          const cachedToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          if (!isTokenExpired(cachedToken.accessToken)) {
            window.localStorage.setItem(
              'cdeSharedApisScopeToken',
              cachedToken.accessToken
            );
            return cachedToken.accessToken;
          }
        } catch (err) {
          console.error(`99: ${err}`);
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === 'InteractionRequiredAuthError') {
            return instance
              .acquireTokenPopup(accessTokenRequest)
              .then((response) => {
                // get access token from response
                window.localStorage.setItem(
                  'cdeSharedApisScopeToken',
                  response?.accessToken
                );
                return type === 'roles'
                  ? response?.account?.idTokenClaims?.roles
                  : response?.accessToken;
              })
              .catch((error) => {
                // handle error
                console.error(`110: ${error}`);
              });
          }
        }
        try {
          const newToken = await instance.acquireTokenPopup(accessTokenRequest);
          window.localStorage.setItem(
            'cdeSharedApisScopeToken',
            newToken.accessToken
          );
          return newToken.accessToken;
        } catch (error) {
          console.error(`110: ${error}`);
        }
      }
      return null;
    }
  } else {
    if (
      window.localStorage.getItem('cdeScopeToken') !== null &&
      window.localStorage.getItem('cdeScopeToken') !== undefined &&
      window.localStorage.getItem('cdeScopeToken') !== 'null'
    ) {
      const token = window.localStorage.getItem('cdeScopeToken');
      if (!isTokenExpired(token)) {
        return token;
      }
      try {
        // const newToken = await instance.acquireTokenPopup(accessTokenRequest);
        const token = await instance.acquireTokenSilent(accessTokenRequest);
        // return token?.accessToken;
        window.localStorage.setItem('cdeScopeToken', token?.accessToken);
        return token?.accessToken;
      } catch (e) {
        console.error(`110: ${e}`);
        console.log('16: silent token error: ', e);
        if (e.name === 'InteractionRequiredAuthError') {
          return instance
            .acquireTokenPopup(accessTokenRequest)
            .then((response) => {
              // get access token from response
              window.localStorage.setItem(
                'cdeScopeToken',
                response?.accessToken
              );
              return type === 'roles'
                ? response?.account?.idTokenClaims?.roles
                : response?.accessToken;
            })
            .catch((error) => {
              console.error(`24: ${error}`);
              throw error;
            });
        } else {
          console.error('Silent token acquisition error: ', e);
          throw e;
        }
      }
    } else {
      // Check for existing token and its validity
      const accounts = instance.getAllAccounts();
      if (accounts?.length > 0) {
        // checkAndSetActiveAccount();
        // await loginSSO(account);
        try {
          const cachedToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          if (!isTokenExpired(cachedToken.accessToken)) {
            window.localStorage.setItem(
              'cdeScopeToken',
              cachedToken.accessToken
            );
            return cachedToken.accessToken;
          }
        } catch (err) {
          console.error(`99: ${err}`);
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === 'InteractionRequiredAuthError') {
            return instance
              .acquireTokenPopup(accessTokenRequest)
              .then((response) => {
                // get access token from response
                window.localStorage.setItem(
                  'cdeScopeToken',
                  response?.accessToken
                );
                return type === 'roles'
                  ? response?.account?.idTokenClaims?.roles
                  : response?.accessToken;
              })
              .catch((error) => {
                // handle error
                console.error(`110: ${error}`);
              });
          }
        }
        try {
          const newToken = await instance.acquireTokenPopup(accessTokenRequest);
          window.localStorage.setItem('cdeScopeToken', newToken.accessToken);
          return newToken.accessToken;
        } catch (error) {
          console.error(`110: ${error}`);
        }
      }
      return null;
    }
  }
}

export { getSilentToken };
