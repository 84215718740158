/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './main.module.css';
import { PortalFooter } from '@weirgroup/weir-dls';
import { fetchFooterLinksCMSData } from './FooterAction';
import { useMsal } from '@azure/msal-react';
import { getSilentToken } from '../../services/TokenUtils';
import { msalConstants } from '../../services/msal.constants';
import { applicationRouteConstants } from '../../shared/_constants';

export default function FooterComponent() {
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();
  const { footerLinksCMSData } = useSelector(
    (state) => state.footerLinksReducer
  );
  useEffect(() => {
    const getTokenAndFetchData = async () => {
      try {
        const token = await getSilentToken(accounts[0], instance, [
          msalConstants.apiApplicationConfig.scopes[0],
        ]);

        if (token) {
          dispatch(fetchFooterLinksCMSData(token));
        } else {
          console.log('Token not retrieved');
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    // Call the function
    getTokenAndFetchData();
  }, [accounts, instance]);

  return (
    <div>
      <div className={styles.footer}>
        <PortalFooter
          CopyrightText={
            footerLinksCMSData?.copyrightText?.structure?.children?.[0]?.children?.[0]?.text.replace(
              '&copy;',
              '©'
            ) || ''
          }
          NavigationLinks={[
            {
              name: footerLinksCMSData?.footerMenu4?.[0]?.text || '',
              url: `${applicationRouteConstants.PRIVACYPOLICY}`,
            },
            {
              name: footerLinksCMSData?.footerMenu4?.[1]?.text || '',
              url: `${applicationRouteConstants.TERMSCONDITIONS}`,
            },
            {
              name: footerLinksCMSData?.footerMenu4?.[2]?.text || '',
              url: `${applicationRouteConstants.COOKIESPOLICY}`,
            },
            {
              name: footerLinksCMSData?.footerMenu4?.[3]?.text || '',
              url: footerLinksCMSData?.footerMenu4?.[3]?.href || '',
              target: '_blank',
            },
          ]}
        />
      </div>
    </div>
  );
}
