import React, { useEffect } from 'react';
import styles from '../Footer/main.module.css';
import { fetchPrivacyPolicyCMSData } from './PrivacyPolicyAction';
import { useDispatch, useSelector } from 'react-redux';
import { msalConstants } from '../../services/msal.constants';
import { getSilentToken } from '../../services/TokenUtils';
import { useMsal } from '@azure/msal-react';
import { Typography, Grid } from '@weirgroup/weir-dls';
import { applicationRouteConstants } from '../../shared/_constants';
/* This generates the Privacy Policy templates */
export default function PrivacyPolicy() {
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();
  const { privacyPolicyCMSData } = useSelector(
    (state) => state.privacyPolicyReducer
  );
  const contentArea =
    privacyPolicyCMSData?.contentArea?.[0]?.contentLink?.expanded?.text
      ?.structure?.children || [];
  useEffect(() => {
    const getTokenAndFetchData = async () => {
      try {
        const token = await getSilentToken(accounts[0], instance, [
          msalConstants.apiApplicationConfig.scopes[0],
        ]);

        if (token) {
          dispatch(fetchPrivacyPolicyCMSData(token));
        } else {
          console.log('Token not retrieved');
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };
    getTokenAndFetchData();
  }, [accounts, instance, dispatch]);

  return (
    <Grid desktopcolumns={8} tabletcolumns={8} mobilecolumns={4}>
      <Typography variant="H1" color="onyx">
        <div
          className={styles.footerPagesContainer}
          data-testid="PRIVACYPOLICY_1"
        >
          {privacyPolicyCMSData?.listingTitle || ''}
        </div>
      </Typography>
      <div className={styles.mainHeadingSpacing} id="PRIVACYPOLICY_2">
        <Typography variant="H4" color="onyx">
          {contentArea?.[0]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_3">
        <Typography variant="P1" color="onyx">
          {contentArea?.[1]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_4">
        <Typography variant="P1" color="onyx">
          {contentArea?.[2]?.children?.[0]?.text
            .replace(/&ldquo;/g, '"')
            .replace(/&rdquo;/g, '"')}
          <a
            href={contentArea?.[2]?.children?.[1]?.url}
            target="_blank"
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentArea?.[2]?.children?.[1]?.children?.[0]?.text}
          </a>
          {contentArea?.[2]?.children?.[2]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_4">
        <Typography variant="P1" color="onyx">
          {contentArea?.[3]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_5">
        <Typography variant="P1" color="onyx">
          {contentArea?.[4]?.children?.[0]?.text
            .replace(/&ldquo;/g, '"')
            .replace(/&rdquo;/g, '"')}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_6">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[5]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_7">
        <Typography variant="P1" color="onyx">
          {contentArea?.[6]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_8">
        <Typography variant="H4" color="onyx">
          {contentArea?.[7]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_9">
        <Typography variant="P1" color="onyx">
          {contentArea?.[8]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_10">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[9]?.children?.[0]?.text}</strong>
          {contentArea?.[9]?.children?.[1]?.text.replace(/&ndash;/g, '-')}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_11">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[10]?.children?.[0]?.text}</strong>
          {contentArea?.[10]?.children?.[1]?.text.replace(/&ndash;/g, '-')}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_12">
        <Typography variant="P1" color="onyx">
          <ul className={styles.ListLeftPadding}>
            <li>{contentArea?.[11]?.children?.[0]?.children?.[0]?.text}</li>
            <li>{contentArea?.[11]?.children?.[1]?.children?.[0]?.text}</li>
          </ul>
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_13">
        <Typography variant="P1" color="onyx">
          {contentArea?.[12]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_14">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[13]?.children?.[0]?.text}</strong>
          {contentArea?.[13]?.children?.[1]?.text.replace(/&ndash;/g, '-')}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_15">
        <Typography variant="H4" color="onyx">
          {contentArea?.[14]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_16">
        <Typography variant="P1" color="onyx">
          {contentArea?.[15]?.children?.[0]?.text.replace(/&rsquo;/g, "'")}
          <a
            href={`${applicationRouteConstants.COOKIESPOLICY}`}
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentArea?.[15]?.children?.[1]?.children?.[0]?.text}
          </a>
          {contentArea?.[15]?.children?.[2]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_17">
        <Typography variant="H4" color="onyx">
          {contentArea?.[16]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_18">
        <Typography variant="P1" color="onyx">
          {contentArea?.[17]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_19">
        <Typography variant="H4" color="onyx">
          {contentArea?.[18]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_20">
        <Typography variant="P1" color="onyx">
          <ul className={styles.ListLeftPadding}>
            <li>{contentArea?.[19]?.children?.[0]?.children?.[0]?.text}</li>
            <li>{contentArea?.[19]?.children?.[1]?.children?.[0]?.text}</li>
            <li>{contentArea?.[19]?.children?.[2]?.children?.[0]?.text}</li>
            <li>{contentArea?.[19]?.children?.[3]?.children?.[0]?.text}</li>
            <li>{contentArea?.[19]?.children?.[4]?.children?.[0]?.text}</li>
          </ul>
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_21">
        <Typography variant="P1" color="onyx">
          {contentArea?.[20]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_22">
        <Typography variant="H4" color="onyx">
          {contentArea?.[21]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_23">
        <Typography variant="P1" color="onyx">
          <ul className={styles.ListLeftPadding}>
            <li>{contentArea?.[22]?.children?.[0]?.children?.[0]?.text}</li>
            <li>{contentArea?.[22]?.children?.[1]?.children?.[0]?.text}</li>
            <li>{contentArea?.[22]?.children?.[2]?.children?.[0]?.text}</li>
            <li>
              {contentArea?.[22]?.children?.[3]?.children?.[0]?.text.replace(
                /&nbsp;/g,
                ' '
              )}
            </li>
            <li>{contentArea?.[22]?.children?.[4]?.children?.[0]?.text}</li>
            <li>{contentArea?.[22]?.children?.[5]?.children?.[0]?.text}</li>
          </ul>
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_24">
        <Typography variant="P1" color="onyx">
          {contentArea?.[22]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_25">
        <Typography variant="P1" color="onyx">
          {contentArea?.[23]?.children?.[0]?.text}
        </Typography>
        <Typography variant="P1" color="onyx" id="PRIVACYPOLICY_26">
          {contentArea?.[24]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_27">
        <Typography variant="H4" color="onyx">
          {contentArea?.[25]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_28">
        <Typography variant="P1" color="onyx">
          {contentArea?.[26]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_29">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[27]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_30">
        <Typography variant="P1" color="onyx">
          {contentArea?.[28]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_31">
        <Typography variant="P1" color="onyx">
          {contentArea?.[29]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_32">
        <Typography variant="P1" color="onyx">
          {contentArea?.[30]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_33">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[31]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_34">
        <Typography variant="P1" color="onyx">
          {contentArea?.[32]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_35">
        <Typography variant="P1" color="onyx">
          {contentArea?.[33]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_36">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[34]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_37">
        <Typography variant="P1" color="onyx">
          {contentArea?.[35]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_38">
        <Typography variant="P1" color="onyx">
          {contentArea?.[36]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_39">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[37]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_40">
        <Typography variant="P1" color="onyx">
          {contentArea?.[38]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_41">
        <Typography variant="P1" color="onyx">
          {contentArea?.[39]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_42">
        <Typography variant="P1" color="onyx">
          {contentArea?.[40]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_43">
        <Typography variant="H4" color="onyx">
          {contentArea?.[41]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_44">
        <Typography variant="P1" color="onyx">
          {contentArea?.[42]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_45">
        <Typography variant="P1" color="onyx">
          {contentArea?.[43]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_46">
        <Typography variant="H4" color="onyx">
          {contentArea?.[44]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_47">
        <Typography variant="P1" color="onyx">
          {contentArea?.[45]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_48">
        <Typography variant="P1" color="onyx">
          {contentArea?.[46]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_49">
        <Typography variant="P1" color="onyx">
          <ul className={styles.ListLeftPadding}>
            <li>{contentArea?.[47]?.children?.[0]?.children?.[0]?.text}</li>
            <li>{contentArea?.[47]?.children?.[1]?.children?.[0]?.text}</li>
            <li>{contentArea?.[47]?.children?.[2]?.children?.[0]?.text}</li>
          </ul>
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_50">
        <Typography variant="P1" color="onyx">
          {contentArea?.[48]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_51">
        <Typography variant="P1" color="onyx">
          <ul className={styles.ListLeftPadding}>
            <li>{contentArea?.[49]?.children?.[0]?.children?.[0]?.text}</li>
            <li>{contentArea?.[49]?.children?.[1]?.children?.[0]?.text}</li>
            <li>{contentArea?.[49]?.children?.[2]?.children?.[0]?.text}</li>
          </ul>
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_52">
        <Typography variant="H4" color="onyx">
          {contentArea?.[50]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_53">
        <Typography variant="P1" color="onyx">
          {contentArea?.[51]?.children?.[0]?.text
            .replace(/&ldquo;/g, '"')
            .replace(/&rdquo;/g, '"')}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_54">
        <Typography variant="P1" color="onyx">
          {contentArea?.[52]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_55">
        <Typography variant="P1" color="onyx">
          {contentArea?.[53]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_56">
        <Typography variant="P1" color="onyx">
          {contentArea?.[54]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_57">
        <Typography variant="H4" color="onyx">
          {contentArea?.[55]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_58">
        <Typography variant="P1" color="onyx">
          {contentArea?.[56]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_59">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[57]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_60">
        <Typography variant="P1" color="onyx">
          {contentArea?.[58]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_61">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[59]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_62">
        <Typography variant="P1" color="onyx">
          {contentArea?.[60]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_63">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[61]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_64">
        <Typography variant="P1" color="onyx">
          {contentArea?.[62]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_65">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[63]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_66">
        <Typography variant="P1" color="onyx">
          {contentArea?.[64]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_67">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[65]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_68">
        <Typography variant="P1" color="onyx">
          {contentArea?.[66]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_69">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[67]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_70">
        <Typography variant="P1" color="onyx">
          {contentArea?.[68]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_71">
        <Typography variant="P1" color="onyx">
          {contentArea?.[69]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_72">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[70]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_73">
        <Typography variant="P1" color="onyx">
          {contentArea?.[71]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_74">
        <Typography variant="P1" color="onyx">
          {contentArea?.[72]?.children?.[0]?.text
            .replace(/&lsquo;/g, "'")
            .replace(/&rsquo;/g, "'")}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_75">
        <Typography variant="P1" color="onyx">
          <strong>{contentArea?.[73]?.children?.[0]?.text}</strong>
        </Typography>
      </div>
      <div id="PRIVACYPOLICY_76">
        <Typography variant="P1" color="onyx">
          {contentArea?.[74]?.children?.[0]?.text}
          <a
            href={contentArea?.[74]?.children?.[1]?.url}
            target="_blank"
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentArea?.[74]?.children?.[1]?.children?.[0]?.text}
          </a>
          {contentArea?.[74]?.children?.[2]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_77">
        <Typography variant="P1" color="onyx">
          {contentArea?.[75]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_78">
        <Typography variant="P1" color="onyx">
          {contentArea?.[76]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_79">
        <Typography variant="P1" color="onyx">
          {contentArea?.[77]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_80">
        <Typography variant="H4" color="onyx">
          {contentArea?.[78]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_81">
        <Typography variant="P1" color="onyx">
          {contentArea?.[79]?.children?.[0]?.text
            .replace(/&lsquo;/g, "'")
            .replace(/&rsquo;/g, "'")}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_82">
        <Typography variant="P1" color="onyx">
          {contentArea?.[80]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="PRIVACYPOLICY_83">
        <Typography variant="H4" color="onyx">
          {contentArea?.[81]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="PRIVACYPOLICY_84">
        <Typography variant="P1" color="onyx">
          {contentArea?.[82]?.children?.[0]?.text}
          <a
            href={contentArea?.[82]?.children?.[1]?.url}
            target="_blank"
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentArea?.[82]?.children?.[1]?.children?.[0]?.text}
          </a>
          {contentArea?.[82]?.children?.[2]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="PRIVACYPOLICY_85">
        <Typography variant="P1" color="onyx">
          {contentArea?.[83]?.children?.[0]?.text.replace(/&amp;/g, '&')}
          <a
            href={contentArea?.[83]?.children?.[1]?.url}
            target="_blank"
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentArea?.[83]?.children?.[1]?.children?.[0]?.text}
          </a>
          {contentArea?.[83]?.children?.[2]?.text}
        </Typography>
      </div>
    </Grid>
  );
}
