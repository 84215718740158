import { APPLICATIONSPAGE_CMS_DATA } from './ApplicationsPageActionTypes';

const initialState = {
  applicationsPageCMSData: [],
};

export const applicationsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATIONSPAGE_CMS_DATA:
      return {
        ...state,
        applicationsPageCMSData: action.payload,
      };
    default:
      return state;
  }
};
