import { combineReducers } from 'redux';
import HubReducer from './modules/Hub/Reducers/HubReducer';
import AuthReducer from './services/Auth/AuthReducer';
import signUp from './modules/SignUp/SignUP/signUp.reducer';
import appsignUp from './modules/AppSignUp/appSignUp.reducer';
import MyAccessReducer from './modules/MyAccess/MyAccess/myApps/Reducers/MyAccessReducer';
import MoreAppsReducer from './modules/MyAccess/MyAccess/moreApps/Reducers/MoreAppsReducer';
import MyActivityReducer from './modules/MyActivity/Reducers/MyActivityReducer';
import loader from './shared/_reducers/loader.reducer';
import LoginReducer from './modules/Login/Login/Reducers/LoginReducer';
import { notification } from './shared/_reducers/notification.reducer';
import { homePageReducer } from './modules/Home/HomePageReducer';
import { applicationsPageReducer } from './modules/Applications/ApplicationsPageReducer';
import { termsConditionsReducer } from './components/TermsConditions/TermsConditionsReducer';
import { privacyPolicyReducer } from './components/PrivacyPolicy/PrivacyPolicyReducer';
import { cookiesPolicyReducer } from './components/CookiesPolicy/CookiesPolicyReducer';
import { footerLinksReducer } from './components/Footer/FooterReducer';

const rootReducer = combineReducers({
  HubReducer,
  AuthReducer,
  signUp,
  MyAccessReducer,
  MoreAppsReducer,
  MyActivityReducer,
  loader,
  LoginReducer,
  appsignUp,
  notification,
  homePageReducer,
  applicationsPageReducer,
  termsConditionsReducer,
  privacyPolicyReducer,
  cookiesPolicyReducer,
  footerLinksReducer,
});

export default rootReducer;
