import { FOOTERLINKS_CMS_DATA } from './FooterActionTypes';

const initialState = {
  footerLinksCMSData: [],
};

export const footerLinksReducer = (state = initialState, action) => {
  if (action.type === FOOTERLINKS_CMS_DATA) {
    return {
      ...state,
      footerLinksCMSData: action.payload,
    };
  } else {
    return state;
  }
};
