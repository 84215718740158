/* eslint-disable no-unused-vars */
import { TERMSCONDITIONS_CMS_DATA } from './TermsConditionsActionTypes';
import SERVICE_CONSTANTS from '../../shared/_constants/services.constants';
import axios from 'axios';
import { serviceFactory } from '../../services/_helpers/serviceFactory';

export const setTermsCondtionsCMSData = (data) => {
  return {
    type: TERMSCONDITIONS_CMS_DATA,
    payload: data,
  };
};

export const fetchTermsCondtionsCMSData = (token) => {
  return (dispatch) => {
    const appendUrl = SERVICE_CONSTANTS.termsConditionsPage;
    const termsConditionsApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`;
    axios
      .get(termsConditionsApi, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(setTermsCondtionsCMSData(response.data));
      })
      .catch((err) => {
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};
