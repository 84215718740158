const SERVICE_CONSTANTS = {
  apiBaseURL: '/api/identity/iamservice',
  getApplicationData: '/applications/me',
  loginSignUpBaseURL: '/api/identity/iamservice',
  signUp: '/Users',
  validateUser: '/users/',
  myAccess: '/user/access',
  moreApps: '/user/access-request/assignable-applications',
  myActivity: '/Users/Activities',
  headersAcceptv1: `application/json; application/${process.env.REACT_APP_CDE_IAMSERVICE_VERSION}${process.env.REACT_APP_CDE_HEADER_VERSION1}`,
  headersAcceptv2: `application/json; application/${process.env.REACT_APP_CDE_IAMSERVICE_VERSION}${process.env.REACT_APP_CDE_HEADER_VERSION2}`,
  openAPIBaseURL: 'https://apis.apps.weir/iamservice',
  appsignUp: '/user/access-request',
  moreAppsSubmit: '/user/access-request/assign-applications',
  homePage:
    '/api/episerver/v3.0/content/?contentUrl=%2F&matchExact=true&expand=*',
  homePageNews: '/api/news/search/?ps=3&old=false',
  applicationsPage:
    '/api/episerver/v3.0/content/?contentUrl=applications&matchExact=true&expand=*',
  moreAppsCmsContent: '/api/episerver/v3.0/content/36891/children',
  termsConditionsPage: '/api/episerver/v3.0/content/7978/?expand=*',
  cookiePolicyPage: '/api/episerver/v3.0/content/7982/?expand=*',
  privacyPolicyPage: '/api/episerver/v3.0/content/7974/?expand=*',
  footerLinks: '/api/episerver/v3.0/content/5/?expand=footerMenu4',
  cookiePolicyTableContent: '/api/table/22032/',
};
export default SERVICE_CONSTANTS;
