/* eslint-disable no-unused-vars */
import SERVICE_CONSTANTS from '../../shared/_constants/services.constants';
import axios from 'axios';
import { serviceFactory } from '../../services/_helpers/serviceFactory';
import { FOOTERLINKS_CMS_DATA } from './FooterActionTypes';

export const setFooterLinksCMSData = (data) => {
  return {
    type: FOOTERLINKS_CMS_DATA,
    payload: data,
  };
};

export const fetchFooterLinksCMSData = (token) => {
  return (dispatch) => {
    const appendUrl = SERVICE_CONSTANTS.footerLinks;
    const cookiesPolicyApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`;
    axios
      .get(cookiesPolicyApi, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(setFooterLinksCMSData(response.data));
      })
      .catch((err) => {
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};
