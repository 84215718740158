import {
  COOKIESPOLICY_CMS_DATA,
  COOKIESPOLICY_TABLE_CMS_DATA,
} from './CookiesPolicyActionTypes';

const initialState = {
  cookiesPolicyCMSData: [],
  cookiesPolicyTableCMSData: [],
};

export const cookiesPolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COOKIESPOLICY_CMS_DATA:
      return {
        ...state,
        cookiesPolicyCMSData: action.payload,
      };
    case COOKIESPOLICY_TABLE_CMS_DATA:
      return {
        ...state,
        cookiesPolicyTableCMSData: action.payload,
      };
    default:
      return state;
  }
};
