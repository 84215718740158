import React, { useEffect } from 'react';
import styles from '../Footer/main.module.css';
import {
  fetchCookiesPolicyCMSData,
  fetchCookiesPolicyTableCMSData,
} from './CookiesPolicyAction';
import { useDispatch, useSelector } from 'react-redux';
import { msalConstants } from '../../services/msal.constants';
import { getSilentToken } from '../../services/TokenUtils';
import { useMsal } from '@azure/msal-react';
import { Table, Typography, Grid } from '@weirgroup/weir-dls';

/* This generates the Cookies Policy templates */
export default function CookiesPolicy() {
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();
  const { cookiesPolicyCMSData, cookiesPolicyTableCMSData } = useSelector(
    (state) => state.cookiesPolicyReducer
  );
  const contentArea =
    cookiesPolicyCMSData?.contentArea?.[0]?.contentLink?.expanded?.text
      ?.structure?.children || [];
  const contentContact =
    cookiesPolicyCMSData?.contentArea?.[2]?.contentLink?.expanded?.text
      ?.structure?.children || [];

  useEffect(() => {
    const getTokenAndFetchData = async () => {
      try {
        const token = await getSilentToken(accounts[0], instance, [
          msalConstants.apiApplicationConfig.scopes[0],
        ]);

        if (token) {
          dispatch(fetchCookiesPolicyCMSData(token));
          dispatch(fetchCookiesPolicyTableCMSData(token));
        } else {
          console.log('Token not retrieved');
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };
    getTokenAndFetchData();
  }, [accounts, instance, dispatch]);

  return (
    <Grid desktopcolumns={8} tabletcolumns={8} mobilecolumns={4}>
      <Typography variant="H1" color="onyx">
        <div
          className={styles.footerPagesContainer}
          data-testid="CookiesPolicy_1"
        >
          {cookiesPolicyCMSData?.listingTitle || ''}
        </div>
      </Typography>
      <div className={styles.mainHeadingSpacing} id="CookiesPolicy_2">
        <Typography variant="H4" color="onyx">
          {contentArea?.[0]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="CookiesPolicy_3">
        <Typography variant="P1" color="onyx">
          {contentArea?.[1]?.children?.[0]?.text
            .replace(/&ldquo;/g, '"')
            .replace(/&rdquo;/g, '"')}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="CookiesPolicy_4">
        <Typography variant="H4" color="onyx">
          {contentArea?.[2]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="CookiesPolicy_5">
        <Typography variant="P1" color="onyx">
          {contentArea?.[3]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="CookiesPolicy_6">
        <Typography variant="P1" color="onyx">
          {contentArea?.[4]?.children?.[0]?.text
            .replace(/&ldquo;/g, '"')
            .replace(/&rsquo;/g, "'")
            .replace(/&rdquo;/g, '"')}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="CookiesPolicy_7">
        <Typography variant="P1" color="onyx">
          {contentArea?.[5]?.children?.[0]?.text
            .replace(/&ldquo;/g, '"')
            .replace(/&rdquo;/g, '"')}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="CookiesPolicy_8">
        <Typography variant="P1" color="onyx">
          {contentArea?.[6]?.children?.[0]?.text}
        </Typography>
      </div>
      <Typography variant="P1" color="onyx">
        <div className={styles.headingParaSpacing} id="CookiesPolicy_9">
          <ul className={styles.ListLeftPadding}>
            <li>
              <strong>
                {contentArea?.[7]?.children?.[0]?.children?.[0]?.text}
              </strong>
              {contentArea?.[7]?.children?.[0]?.children?.[1]?.text}
            </li>
            <li>
              <strong>
                {contentArea?.[7]?.children?.[1]?.children?.[0]?.text}
              </strong>
              {contentArea?.[7]?.children?.[1]?.children?.[1]?.text}
            </li>
            <li>
              <strong>
                {contentArea?.[7]?.children?.[2]?.children?.[0]?.text}
              </strong>
              {contentArea?.[7]?.children?.[2]?.children?.[1]?.text}
            </li>
            <li>
              <strong>
                {contentArea?.[7]?.children?.[3]?.children?.[0]?.text}
              </strong>
              {contentArea?.[7]?.children?.[3]?.children?.[1]?.text}
            </li>
          </ul>
        </div>
      </Typography>
      <div className={styles.paraTopSpacing} id="CookiesPolicy_10">
        <Typography variant="H4" color="onyx">
          {contentArea?.[8]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="CookiesPolicy_11">
        <Typography variant="P1" color="onyx">
          {contentArea?.[9]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.paraSpacing} id="CookiesPolicy_12">
        <Typography variant="P1" color="onyx">
          {contentArea?.[10]?.children?.[0]?.text
            .replace(/&ldquo;/g, '"')
            .replace(/&rdquo;/g, '"')}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="CookiesPolicy_13">
        <Typography variant="H4" color="onyx">
          {contentArea?.[11]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="CookiesPolicy_14">
        <Typography variant="P1" color="onyx">
          {contentArea?.[12]?.children?.[0]?.text}
          <a
            href={contentArea?.[12]?.children?.[1]?.url}
            target="_blank"
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentArea?.[12]?.children?.[1]?.children?.[0]?.text}
          </a>
          {contentArea?.[12]?.children?.[2]?.text.replace(/&nbsp;/g, ' ')}
          <a
            href={contentArea?.[12]?.children?.[3]?.url}
            target="_blank"
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentArea?.[12]?.children?.[3]?.children?.[0]?.text}
          </a>
          {contentArea?.[12]?.children?.[4]?.text}
        </Typography>
      </div>
      <div className={styles.paraTopSpacing} id="CookiesPolicy_15">
        <Typography variant="H4" color="onyx">
          {contentArea?.[13]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="CookiesPolicy_16">
        <Table
          title={'Cookies'}
          header={[
            {
              id: 'col220321',
              key: 'col220321',
              label: 'NAME',
              type: 'string',
            },
            {
              id: 'col220322',
              key: 'col220322',
              label: 'SOURCE',
              type: 'string',
            },
            {
              id: 'col220323',
              key: 'col220323',
              label: 'PURPOSE',
              type: 'string',
            },
            {
              id: 'col220324',
              key: 'col220324',
              label: 'EXPIRATION',
              type: 'string',
            },
          ]}
          source={
            cookiesPolicyTableCMSData?.data?.map((item) => ({
              col220321: item?.col220321 || '',
              col220322: item?.col220322 || '',
              col220323: item?.col220323 || '',
              col220324: item?.col220324 || '',
            })) || []
          }
          tableType="default"
        />
      </div>
      <div className={styles.paraTopSpacing} id="CookiesPolicy_17">
        <Typography variant="H4" color="onyx">
          {contentContact?.[0]?.children?.[0]?.text}
        </Typography>
      </div>
      <div className={styles.headingParaSpacing} id="CookiesPolicy_18">
        <Typography variant="P1" color="onyx">
          {contentContact?.[1]?.children?.[0]?.text}
          <a
            href={contentContact?.[1]?.children?.[1]?.url}
            target="_blank"
            alt=""
            rel="noreferrer"
            className={styles.redirectionLink}
          >
            {contentContact?.[1]?.children?.[1]?.children?.[0]?.text}
          </a>
          {contentContact?.[1]?.children?.[2]?.text}
        </Typography>
      </div>
    </Grid>
  );
}
